<template>
  <div>
    <div class="text-dark font-bold mb-2">{{ $t('reasonForm.selectTitle') }}</div>
    <Multiselect
      :canClear="false"
      v-model="form.reason_id"
      :options="getSectionPredefinedReasons"
      label="description"
      valueProp="id"
      :noOptionsText="$t('validation.noItemsToShow')"
      :placeholder="$t('placeholders.reason')"
    />

    <textarea
      v-model="form.note"
      :placeholder="$t('placeholders.enter_reason_details')"
      class="bg-white input px-2 w-full border border-gray outline-none rounded-md mt-4"
      rows="4" cols="50"
    />
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import { PredefinedReasons } from '@/store/modules/predefined-reasons/actions'
export default {
  name: 'ReasonForm',
  components: {
    Multiselect
  },
  props: {
    feedbackType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      form: {
        reason_id: null,
        note: ''
      }
    }
  },
  mounted () {
    if (this.predefinedReasons.length) return
    this.$store.dispatch(`predefinedReasons/${PredefinedReasons.FETCH_REASONS}`)
  },
  computed: {
    predefinedReasons () {
      return this.$store.getters['predefinedReasons/getPredefinedReasons']
    },
    getSectionPredefinedReasons () {
      return this.predefinedReasons.filter((r) => r.feedbackType === this.feedbackType)
    }
  },
  watch: {
    form: {
      handler (newVal) {
        this.$emit('reason', newVal)
      },
      deep: true
    }
  }
}
</script>
